.address-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background: var(--container-bg);
    border-radius: 8px;
    padding: 10px;
    gap: 10px;
}

.address-wrapper .address-component {
    max-width: 455px;
    background: var(--body-background);
    width: 100%;
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 10px;
}


.address-wrapper .address-component input[type='radio'] {
    appearance: none;
    width: 10px !important;
    align-self: center !important;
    margin: 0 !important;
    height: 10px !important;
    border-radius: 50%;
    border: 0 !important;
    outline-offset: 3px;
    outline: 1px solid;
    margin-right: 3px !important;
}

.address-wrapper .address-component input[type='radio']:checked{
    background-color: var(--secondary-color);
    padding: 5px;
    outline-offset: 3px;
    outline: 1px solid;
}
.address-wrapper .address-component label span {
    font-size: 16px;
    font-weight: 700;
}

.address-wrapper .address-component label span.home {
    padding: 3px 8px;
    font-size: 12px;
    font-weight: 400;
    background-color: var(--secondary-color-light);
    
    color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    border-radius: 5px;
}

.address-wrapper .address-component .edit {
    background: var(--secondary-color-light);
    padding: 5px;
    border-radius: 2px;
}

.address-wrapper .address-component .remove {
    background: var(--bg-danger);
    padding: 5px;
    border-radius: 2px;
}

.address-wrapper .address-component .address,
.address-wrapper .address-component .mobile{
    color: var(--sub-text-color);
    font-size: 1.555rem;
}

.address-wrapper .address-component .address{
    margin-top: 20px;
}

.address-wrapper .address-component .mobile{
    margin-top: 30px;
}

.address-wrapper .new-address {
    border-style: dashed;
}

.address-wrapper .new-address button{
    width: 100%;
    height: 100%;
    background: none;
    font-size: 1.655rem;
    font-weight: 700;
}


.new-address h5 {
    font-size: 2.2rem;
    font-weight: bolder;
    
}

.new-address .header button {
    background: none;
    font-size: 3rem;
}

.new-address .address-details-wrapper{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.new-address .address-details-wrapper h3{
    font-size: 1.765rem;
    font-weight: 800;
    
}

.new-address .address-details-wrapper .contact-details,
.new-address .address-details-wrapper .address-details{
    display: flex;
    flex-wrap: wrap;
}

.new-address .address-details-wrapper input[type='text'],
.new-address .address-details-wrapper input[type='number'], .new-address .address-details-wrapper span{
    padding: 8px;
    width: 100%;
    margin: 3px;
    background: var(--container-bg);
    border: 0.9px solid #ddd;
    border-radius: 5px;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 500;
}
.new-address .address-details-wrapper span.disabled {
    background: var(--body-background);
    color: #ddd;
    display: flex;
    justify-content: center;
    
}
.new-address .address-details-wrapper .address-type input[type='radio']{
    appearance: none;
    display: none;

}

.new-address .address-details-wrapper .address-type input[type='radio'] + label{
    padding: 5px 15px;
    margin: 0 4px;
    border-radius: 5px;
    font-size: 1.755rem;
    background: var(--container-bg);
    border: 0.5px solid var(--body-background);
    cursor: pointer;
}

.new-address .address-details-wrapper .address-type input[type='radio']:checked + label {
    background-color: var(--secondary-color);
    color: var(--text-field-color);
}
.new-address .address-details-wrapper .default-address{
    margin-top: 10px;
    font-size: 1.455rem;
    font-weight: 800;

}
.new-address .address-details-wrapper input::placeholder {
    text-align: start !important;
}
.new-address .address-details-wrapper button[type='submit'],
.new-address button.confirm-address{
    background: var(--secondary-color);
    color: var(--text-field-color);
    padding: 8px;
    border-radius: 8px;
    font-weight: 400;
    font-size: 16px;
}

.new-address button.confirm-address{
    margin-top: 30px;
}

@media screen and (max-width: 48rem){
    .address-wrapper .address-component{
        /* min-width: 80vw; */
    }
}

@media (min-width: 990px) {
    .map-marker{
        height: 100%;
    }
    .address-details-wrapper {
        margin-top: 0 !important;
    }
}